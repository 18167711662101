import { Box, Container } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';
import FTabs from '../../../ui/fairtreeComponents/tab/fTabs';
import FTab from '../../../ui/fairtreeComponents/tab/fTab';
import FTabPanel from '../../../ui/fairtreeComponents/tab/fTabPanel';

const AccountTabs = ({ children, tabs }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ mb: 3 }}>
      <Box sx={{ mb: 3 }}>
        <FTabs value={value} onChange={handleChange} scrollButtons="auto" variant="scrollable">
          {tabs.map((tab, index) => (
            <FTab label={tab.title} key={index} />
          ))}
        </FTabs>
      </Box>

      {children}

      {tabs.map((tab, index) => (
        <FTabPanel key={index} value={value} index={index}>
          {tab.content}
        </FTabPanel>
      ))}
    </Container>
  );
};

export default AccountTabs;

AccountTabs.propTypes = {
  children: PropTypes.node.isRequired,
  tabs: PropTypes.array.isRequired
};
