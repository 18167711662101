import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearSearch, getBalances, search } from '../../../features/client/client.slice';
import { getServiceProviders } from '../../../features/serviceProvider/serviceProvider.slice';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  MenuItem,
  Paper,
  Typography
} from '@mui/material';
import PrimaryButton from '../../ui/buttons/primaryButton';
import CircularProgress from '../../ui/controls/circularProgress';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Form from '../../ui/forms';
import Input from '../../ui/forms/fields/input';
import Select from '../../ui/forms/fields/select';
import { Box, useTheme } from '@mui/system';
import { useTitle } from '../../../hooks/titleProvider';
import { useNavigate } from 'react-router-dom';

function Clients() {
  const { setTitle } = useTitle();
  useEffect(() => {
    setTitle('Clients');
    return () => {
      setTitle('');
    };
  }, []);

  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const searching = useSelector((state) => state.client.searching);
  const results = useSelector((state) => state.client.results);
  const serviceProviders = useSelector((state) => state.serviceProvider.providers);

  const schema = yup.object().shape({
    serviceProvider: yup.string().required('Service Provider is required'),
    term: yup.string()
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    dispatch(getServiceProviders());

    return () => {
      //Clear search results
      dispatch(clearSearch());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(search(data));
  };

  return (
    <Box>
      <Paper
        sx={{
          padding: theme.spacing(2),
          backgroundColor: theme.palette.background.paper,
          border: 'none',
          boxShadow: 'none'
        }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display="flex"
            flexDirection={'column'}
            justifyContent="space-between"
            alignItems="center">
            <Select
              label="Service Provider"
              control={control}
              name="serviceProvider"
              error={errors.serviceProvider}
              defaultValue="all">
              <MenuItem value={'all'}>All</MenuItem>
              {serviceProviders &&
                serviceProviders.map(
                  (provider) =>
                    provider.mode === 'active' && (
                      <MenuItem key={provider._id} value={provider._id}>
                        {provider.name}
                      </MenuItem>
                    )
                )}
            </Select>
            <Input
              label="Search  (Name, ID Number / Registration Number, Account Number)"
              {...register('term')}
              error={errors.term}
            />
            <PrimaryButton type="submit">Search</PrimaryButton>
          </Box>
        </Form>
      </Paper>

      {searching ? (
        <CircularProgress caption="Searching" />
      ) : results && results.length > 0 ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography m={2} variant="h6">
            {results.length} Search Results
          </Typography>

          {results &&
            results.map((client) => {
              let clientName = '';

              if (
                client.entityName !== null &&
                client.entityName !== '' &&
                client.entityName !== undefined
              ) {
                clientName = `${client.entityName}`;
              } else {
                clientName = `${client.firstName} ${client.lastName}`;
              }

              return (
                <Card
                  key={client._id}
                  raised={false}
                  sx={{
                    margin: 1,
                    width: '100%',
                    maxWidth: '700px',
                    background: `${theme.palette.background.paper} !important`,
                    boxShadow: 'none !important',
                    border: 'none !important',
                    borderRadius: '4px !important'
                  }}>
                  <CardHeader
                    title={`${clientName}`}
                    subheader={`${client.clientId}`}
                    sx={{
                      '.MuiCardHeader-title': {
                        fontSize: '0.7rem'
                      },
                      '.MuiCardHeader-subheader': {
                        fontSize: '0.6rem',
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <CardContent>
                    <Box>
                      {client.accounts &&
                        client.accounts.map((account) => (
                          <Box key={account} display={'flex'} flexDirection={'column'}>
                            <Typography variant="body2" fontSize={'10px'}>
                              {account}
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </CardContent>
                  <CardActions
                    disableSpacing
                    sx={{ width: '100%', justifyContent: 'center', padding: '0' }}>
                    <PrimaryButton onClick={() => navigate(`/admin/clients/${client._id}`)}>
                      View
                    </PrimaryButton>
                  </CardActions>
                </Card>
              );
            })}
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h6">No Results Found</Typography>
        </Box>
      )}
    </Box>
  );
}

export default Clients;
